import React, { useLayoutEffect, useState, useRef } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import SettingsIcon from '@mui/icons-material/Settings'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { Tag } from '@jeeves/new-components'
import { EditLabel } from '@jeeves/pages/DataLabels/components/EditLabel'
import { DeleteLabel } from '../../Dialogs'

export const DataLabelHeader = ({ dataLabel }) => {
  const [deleteLabelOpen, setDeleteLabelOpen] = React.useState(false)
  const [editLabelOpen, setEditLabelOpen] = React.useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleOpenEdit = () => {
    setEditLabelOpen(true)
    handleCloseSettings()
  }

  const handleOpenDelete = () => {
    setDeleteLabelOpen(true)
    handleCloseSettings()
  }
  const handleOpenSettings = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseSettings = () => {
    setAnchorEl(null)
  }
  return (
    <Box sx={{ pl: 4, pr: 2.2, py: 2, border: 1, borderColor: 'cyralColors.grey.200' }}>
      <EditLabel
        dataLabel={dataLabel}
        editLabelOpen={editLabelOpen}
        setEditLabelOpen={setEditLabelOpen}
      ></EditLabel>
      <DeleteLabel
        dataLabel={dataLabel}
        deleteLabelOpen={deleteLabelOpen}
        setDeleteLabelOpen={setDeleteLabelOpen}
      ></DeleteLabel>
      <Stack direction="row" spacing={1}>
        <Stack direction="row">
          <Typography variant="h3">{dataLabel.name}</Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          {' '}
          {dataLabel.tags.map(tag => (
            <Tag size="md">{tag}</Tag>
          ))}
        </Stack>
        <Stack direction="row" sx={{ flexGrow: 1 }}></Stack>
        <IconButton
          aria-label="settings"
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleOpenSettings}
        >
          <SettingsIcon></SettingsIcon>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseSettings}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleOpenEdit}>Edit</MenuItem>
          <MenuItem onClick={handleOpenDelete}>Delete</MenuItem>
        </Menu>
      </Stack>

      <Stack sx={{ mt: 2 }} direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography>{dataLabel.description}</Typography>
      </Stack>
    </Box>
  )
}
