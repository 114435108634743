import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { createFragmentRegistry } from '@apollo/client/cache'

import generatedPossibleTypes from '@jeeves/graphql/fragment-matches'

import { SidecarHeader_queryFragment } from '@jeeves/pages/WrapperDetail/components/SidecarHeader/SidecarHeader'
import { AdvancedTab_queryFragment } from '@jeeves/pages/WrapperDetail/components/Advanced/Advanced'
import { Config_QueryFragment } from '@jeeves/pages/RepositoryDetail/Tabs/Config/Config'
import { MongoAccordionContent_RepoFragment } from '@jeeves/pages/AccessPortal/components/AccessPortalMain/components/RepoCards/GeneralDataRepoCard/components/AccordionContent/MongoAccordionContent/MongoAccordionContent'
import { StepTwo_UserAccountFragment } from '@jeeves/pages/AccessPortal/components/AccessPortalMain/components/RepoCards/ExpandableDataRepoCard/components/AccordionContent/S3AccordionContent/components/AwsCli'
import { RepositoryAutocomplete_ControlPlaneUserRepos_ConnectionFragment } from '@jeeves/pages/AccessPortal/components/RequestAccessDialog/RepositoryAutocomplete/RepositoryAutocomplete'

export const cache = new InMemoryCache({
  possibleTypes: {
    ...generatedPossibleTypes.possibleTypes,
    DiscoveredAccount: [
      'NormalDiscoveredAccount',
      'OracleDiscoveredAccount',
      'SQLServerUserDiscoveredAccount',
      'SQLServerLoginDiscoveredAccount',
    ],
  },
  typePolicies: {
    ControlPlaneUser: {
      fields: {
        repos: relayStylePagination(['accessibility', 'filters']),
      },
    },
    Query: {
      fields: {
        reposWithUtilities: {
          keyArgs: ['filters'],
          merge(existing = { edges: [] }, incoming, { args, readField }) {
            return incoming
          },
        },
      },
    },
    PostgreSQLRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    MongoDBRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    NormalRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    OracleRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    RedshiftRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    SQLServerRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    DenodoRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    Repo: {
      fields: {
        enhancedAuditLogs: relayStylePagination(['filter']),
      },
    },
    Sidecar: {
      fields: {
        bindings: relayStylePagination(['filters']),
      },
    },
    SidecarCredential: {
      keyFields: ['clientId'],
    },
  },
  fragments: createFragmentRegistry(
    SidecarHeader_queryFragment,
    AdvancedTab_queryFragment,
    Config_QueryFragment,
    StepTwo_UserAccountFragment,
    RepositoryAutocomplete_ControlPlaneUserRepos_ConnectionFragment,
    MongoAccordionContent_RepoFragment
  ),
})
