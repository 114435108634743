import { Box, Stack, Typography } from '@mui/material'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'

const UnableToRequestAccess = () => {
  return (
    <Box sx={{ pt: 4 }}>
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <WarningAmberRoundedIcon sx={{ fontSize: 64, color: 'secondary.main' }} />
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Your admin has not configured Access Portal access requests.
        </Typography>
      </Stack>
    </Box>
  )
}

export default UnableToRequestAccess
