/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { useRouteMatch } from 'react-router-dom'

const navWidth = navOpen => {
  if (navOpen === null) return '0px'
  return navOpen ? '13rem' : '68px'
}

export const StyledDashboard = ({ children }) => {
  const useWhiteBackground = useRouteMatch([
    '/access-portal',
    '/dataLabels',
    '/overview',
    '/settings',
    '/sidecars',
  ])
  const grey = '#f0f2f5'
  const white = '#FFF'

  return (
    <div
      css={{
        position: 'relative',
        minHeight: '100vh',
        backgroundColor: useWhiteBackground ? white : grey,
      }}
    >
      {children}
    </div>
  )
}

export const Main = ({ children }) => {
  return (
    <main
      css={{
        flex: 1,
      }}
    >
      {/* Content Container */}
      <div
        css={{
          padding: '40px 40px 95px',
          position: 'relative',
          minWidth: '1200px',
          overflowX: 'auto',
        }}
      >
        {children}
      </div>
    </main>
  )
}

export const RightSide = ({ navOpen, children }) => {
  return (
    <div
      css={t => css`
        padding-left: ${navWidth(navOpen)};
        transition: padding-left 0.2s;
        transform: translateZ(0);
        display: flex;
        flex-direction: column;
      `}
    >
      {children}
    </div>
  )
}

export const NavigationContainer = ({ navOpen, children }) => {
  return (
    <div
      css={t => css`
        position: fixed;
        top: 0;
        bottom: 0;
        width: ${navWidth(navOpen)};
        display: flex;
        flex-direction: column;
        transition: width 0.2s;
        transform: translateZ(0);
        z-index: 1249;
      `}
    >
      {children}
    </div>
  )
}
