import { Stack } from '@mui/material'

import {
  Credentials,
  General,
  DeleteSidecar,
  SmartPorts,
  Logging,
  OperationalMode,
} from './components'
import { graphql } from '@jeeves/graphql'

// TODO: add a fragment for the SmartPorts component here
export const AdvancedTab_queryFragment = graphql(`
  fragment AdvancedTab_query on Query {
    ...DeleteSidecar_query
    ...General_query
    ...Logging_query
    ...OperationalMode_QueryFragment
  }
`)

const Advanced = ({ sidecar, query }) => {
  return (
    <Stack spacing={4}>
      <General query={query} />
      <Credentials />
      <Logging query={query} />
      <OperationalMode query={query} sidecar={sidecar} />
      <SmartPorts />
      <DeleteSidecar query={query} />
    </Stack>
  )
}

export default Advanced
