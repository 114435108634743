import * as React from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Dialog,
  Switch,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
} from '@mui/material'
import { useMutation } from '@apollo/client'

import { graphql } from '@jeeves/graphql'
import { Tag, useToast, Button } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

import {
  InputLabel,
  Input,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '../Custom/Dialogs/Dialogs.styles'
import { DATALABELS_QUERY } from '../DataLabels'

const UPDATE_DATA_LABEL = graphql(`
  mutation UpdateDatalabel($datalabelName: String!, $input: UpdateDatalabelInput!) {
    updateDatalabel(datalabelName: $datalabelName, input: $input) {
      datalabel {
        description
        name
        tags
        classificationRule {
          enabled
        }
      }
    }
  }
`)

export const EditLabel = ({ editLabelOpen, setEditLabelOpen, dataLabel }) => {
  const [tags, setTags] = React.useState(dataLabel.tags)
  const isPredefinedDataLabel = dataLabel.type === 'PREDEFINED'
  const isCustomDatalabel = dataLabel.type === 'CUSTOM'

  const { toast } = useToast()
  const { control, getValues, reset, setValue, handleSubmit } = useForm({
    defaultValues: {
      name: dataLabel.name,
      description: dataLabel.description,
    },
  })

  const handleOnClose = () => {
    setEditLabelOpen(false)
    setTags(dataLabel.tags)
    reset()
  }

  const [updateDatalabel, { loading }] = useMutation(UPDATE_DATA_LABEL, {
    refetchQueries: [DATALABELS_QUERY],
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) || 'An error occurred while updating your data label.',
      })
    },
    onCompleted: data => {
      toast({
        variant: 'success',
        description: 'Successfully updated data label.',
      })
      reset({
        name: data.updateDatalabel.datalabel.name,
        description: data.updateDatalabel.datalabel.description,
        ...(isPredefinedDataLabel && {
          enabled: data.updateDatalabel.datalabel.classificationRule.enabled,
        }),
      })
      setEditLabelOpen(false)
    },
  })

  const onSubmit = async () => {
    const { name, description, enabled = '' } = getValues()

    const input = {
      ...(isCustomDatalabel && {
        custom: {
          description,
          tags,
        },
      }),

      ...(isPredefinedDataLabel && {
        predefined: {
          tags,
          enabled,
        },
      }),
    }

    try {
      await updateDatalabel({
        variables: { datalabelName: name, input },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleTagDelete = tag => {
    const newTags = tags.filter(t => t !== tag)
    setTags(newTags)
  }

  const handleAddTag = () => {
    const currentTag = getValues('currentTag')
    const newTags = [...tags, currentTag]

    setTags(newTags)
    setValue('currentTag', '')
  }

  return (
    <div>
      <Dialog open={editLabelOpen} onClose={handleOnClose} fullWidth sx={{ radius: '12px' }}>
        <DialogTitle>Edit Data Label</DialogTitle>
        <DialogContent>
          <Stack sx={{ mt: 0.5 }}>
            <form onSubmit={handleSubmit(data => {})}>
              <InputLabel>Name</InputLabel>

              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Name your label..."
                    fullWidth
                    disabled
                    input={<Input />}
                  ></Input>
                )}
              />

              <InputLabel>Tags</InputLabel>
              <Stack direction="row" justifyContent="space-between">
                <Controller
                  name="currentTag"
                  control={control}
                  render={({ field }) => (
                    <Input
                      sx={{ width: '85%' }}
                      placeholder="Add a tag..."
                      {...field}
                      fullWidth
                    ></Input>
                  )}
                />
                <Button
                  sx={{ alignSelf: 'start' }}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={handleAddTag}
                >
                  Add
                </Button>
              </Stack>

              <Box
                sx={{
                  border: `1px solid`,
                  borderColor: 'cyralColors.grey.200',
                  width: '100%',
                  height: '77px',
                  mb: 2,
                  padding: 1,
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ flexWrap: 'wrap', alignItems: 'flex-end' }}
                >
                  {tags.map((tag, idx) => (
                    <Tag key={idx} onRemove={() => handleTagDelete(tag)}>
                      {tag}
                    </Tag>
                  ))}
                </Stack>
              </Box>

              <InputLabel>Description</InputLabel>

              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Name your label..."
                    rows={6}
                    cols={60}
                    multiline
                    disabled={isPredefinedDataLabel}
                    fullWidth
                    input={<Input />}
                  ></Input>
                )}
              />
              {isPredefinedDataLabel && (
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: 'cyralColors.grey.200',
                    borderRadius: theme => theme.radii.base,
                    paddingX: 2,
                    paddingY: 1,
                    alignItems: 'baseline',
                  }}
                >
                  <FormControl fullWidth variant="standard">
                    <Controller
                      name="enabled"
                      control={control}
                      defaultValue={dataLabel.classificationRule.enabled}
                      render={({ field: { value, ...field } }) => (
                        <FormControlLabel
                          control={<Switch checked={value} {...field} />}
                          label={
                            <Typography variant="h6" sx={{ color: 'text.primary' }}>
                              Enable Data Label
                            </Typography>
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              )}
            </form>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose} variant="text" disabled={loading}>
            Cancel
          </Button>

          <Button variant="contained" onClick={onSubmit} loading={loading}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
