import { z } from 'zod'
import { ApolloError } from '@apollo/client'

export const MIN_PORT_NUMBER = 1
export const MAX_PORT_NUMBER = 65535

export const INVALID_PORT_MESSAGE = `Invalid port number`

export const isValidPort = (portNumber: number): Boolean => {
  return (
    Number.isInteger(portNumber) && portNumber >= MIN_PORT_NUMBER && portNumber <= MAX_PORT_NUMBER
  )
}

export const portNumberSchema = z
  .number({
    required_error: 'Please enter a port.',
    invalid_type_error: INVALID_PORT_MESSAGE,
  })
  .refine(isValidPort, () => ({
    message: INVALID_PORT_MESSAGE,
  }))

export const getGraphQLErrorMessage = (error: ApolloError): string =>
  error.graphQLErrors[0].extensions?.response?.body?.message ||
  error.graphQLErrors[0].extensions?.response?.body?.Message ||
  error.graphQLErrors[0].message ||
  ''
