import { useAppConfig } from '@jeeves/hooks'

const useEntitlements = () => {
  const { license } = useAppConfig()

  const hasFeature = featureName =>
    Boolean(license?.features?.some(f => f.internalName === featureName))

  const policyMonitoring = () => {
    return hasFeature('AllowPolicyMonitoring')
  }
  const policyBlocking = () => {
    return hasFeature('AllowPolicyBlocking')
  }

  return {
    policyMonitoring,
    policyBlocking,
    hasAutomaticDatamap: hasFeature('AllowDataDiscovery'),
  }
}

export default useEntitlements
